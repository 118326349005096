<template>
  <v-container class="login-container">
    <v-snackbar v-model="show_snack" timeout="1500">
      Un message a été envoye à {{ email }}
    </v-snackbar>
    <v-container v-if="show_login">
      <h2>Se connecter à l'interface d'administration</h2>
      <v-form @submit.prevent="do_login">
        <v-text-field
          v-model="username"
          label="Username"
          dark
          outlined
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="Mot de passe"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          outlined
          dark
        ></v-text-field>
        <v-row v-if="has_error_message"
          >{{ $store.getters.login_error_message }}
        </v-row>
        <v-row>
          <v-spacer />
          <div @click="show_login = false">Mot de passe oublié?</div>
        </v-row>
        <v-row class="btn-row">
          <v-spacer></v-spacer>
          <v-btn type="submit">Log In</v-btn>
        </v-row>
      </v-form>
    </v-container>
    <v-container v-else>
      <h2>Mot de passe perdu</h2>
      <v-form @submit.prevent="get_lost_password_mail">
        <v-text-field
          v-model="email"
          outlined
          dark
          label="Email"
        ></v-text-field>
        <v-row class="btn-row">
          <v-spacer></v-spacer>
          <v-btn type="submit">Envoyer</v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.login-container {
  margin-top: 25px;
  padding: 25px;
  max-width: 50%;
  border: 1px solid black;
  border-radius: 10px;
}

.btn-row {
  padding: 0 20px;
}
</style>

<script>
export default {
  name: "LoginView",
  metaInfo: {
    title: "Connexion",
  },
  data() {
    return {
      show_login: true,
      username: "",
      password: "",
      showPassword: false,
      email: "",
      show_snack: false,
    };
  },
  mounted() {
    if (this.$cookies.isKey("aneya-refresh")) {
      const cookie = this.$cookies.get("aneya-refresh");
      this.$store.commit("set_refresh_token", cookie);
      this.$store.dispatch("do_refresh").then(() => {
        if (this.$store.getters.is_logged) {
          this.$router.push({ name: "admin" });
        }
      });
    }
  },
  methods: {
    do_login() {
      this.$store.commit("set_credential", {
        username: this.username,
        password: this.password,
      });
      this.$store.dispatch("do_login").then(() => {
        if (this.$store.getters.is_logged) {
          this.$router.push({ name: "admin" });
        }
      });
    },
    get_lost_password_mail() {
      if (this.email) {
        this.$axios
          .post("/password-reset/", { email: this.email })
          .then((response) => {
            if (response.status === 200) {
              this.show_snack = true;
              this.show_login = true;
            }
          });
      }
    },
  },
  computed: {
    has_error_message() {
      return this.$store.getters.login_error_message.length > 0;
    },
  },
};
</script>

<style scoped></style>
