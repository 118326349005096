<template>
  <div class="full-container">
    <v-img
      class="article-container"
      :src="article.main_image"
      @click="dialog = !dialog"
    >
      <div class="article-titre">
        {{ article.titre }}
      </div>

      <ribbon-component v-if="is_recent" />
      <v-dialog
        v-model="dialog"
        :max-width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
        class="dialog-box"
      >
        <v-card class="dialog-card">
          <v-card-title
            >{{ article.titre }}
            <v-spacer />
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row
              :class="
                $vuetify.breakpoint.lgAndUp
                  ? 'article-content'
                  : 'article-content-mobile'
              "
            >
              <v-col
                cols="12"
                lg="6"
                :class="$vuetify.breakpoint.lgAndUp ? 'article-texte' : ''"
              >
                <div class="chip-box">
                  <v-chip
                    v-for="tag in article.tags"
                    :key="tag"
                    :color="$store.getters.tag(tag).color"
                  >
                    {{ $store.getters.tag(tag).name }}
                  </v-chip>
                </div>
                <v-container
                  class="markdown-container"
                  v-html="compiled_texte"
                ></v-container>
              </v-col>
              <v-col cols="12" lg="6" class="article-image">
                <v-img
                  v-if="article.main_image"
                  :src="article.main_image"
                  max-height="55vh"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialog = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-img>
    <div v-if="false" class="chip-box">
      <v-chip
        v-for="tag in article.tags"
        :key="tag"
        :color="$store.getters.tag(tag).color"
      >
        {{ $store.getters.tag(tag).name }}
      </v-chip>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/markdown.scss";
</style>

<style scoped lang="scss">
$dimension: 300px;
.full-container {
  //@extend .border;
  padding: 1em;
}

.chip-box {
  display: flex;
  gap: 5px;
}

.dialog-box {
  height: 100%;
}

.dialog-card {
}

.article-container {
  @extend .border-small;
  //border: 1px black solid;
  //border-radius: 10px;
  margin: 5px auto;
  background-size: cover;
  padding: 0;
  position: relative;
  width: $dimension;
  padding-top: 5px;
  height: $dimension;
}

.article-container .article-titre {
  color: black;
  //background-color: lightgrey;
  border-radius: 10px;
  font-size: xx-large;
  display: none;
  text-align: center;
  padding: 10px;
  margin: 80px 5px 5px;
}

.article-container:hover {
}

.article-container:hover .article-titre {
  display: block;
  backdrop-filter: blur(5px);
  background-color: #f0f0f085;
  cursor: pointer;
}

.article-card {
  max-width: 60%;
  max-height: 60vh;
}

.article-content {
  max-height: 60vh;
}

.article-content-mobile {
  height: 60vh;
  overflow-y: auto;
  flex-wrap: wrap-reverse;
}

.article-texte {
  height: 60vh;
  overflow-y: auto;
}

.article-texte::-webkit-scrollbar {
  // background-color: red;
}

.article-image {
  margin: auto;
}
</style>

<script>
import { marked } from "marked";
import dayjs from "dayjs";
import RibbonComponent from "@/components/utils/RibbonComponent";

export default {
  name: "ActuArticle",
  components: { RibbonComponent },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      creation_date: dayjs(this.article.date_creation),
    };
  },
  computed: {
    compiled_texte() {
      return marked(this.article.texte);
    },
    image_style() {
      if (this.article.main_image) {
        return { backgroundImage: `url(${this.article.main_image});` };
      } else {
        return {};
      }
    },
    is_recent() {
      const limit = dayjs().subtract(1, "week");
      return this.creation_date.isAfter(limit);
    },
  },
};
</script>
