<template>
  <v-container></v-container>
</template>

<script>
export default {
  name: "SitePlan",
  metaInfo: {
    title: "Plan du site",
  },
};
</script>

<style scoped></style>
