<template>
  <v-img class="article-container" :src="faq.image" @click="dialog = !dialog">
    <div class="article-titre">
      {{ faq.question }}
    </div>
    <v-dialog
      v-model="dialog"
      :max-width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
      class="dialog-box"
    >
      <v-card class="dialog-card">
        <v-card-title
          >{{ faq.question }}
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row
            :class="
              $vuetify.breakpoint.lgAndUp
                ? 'article-texte-groupe'
                : 'article-texte-groupe-mobile'
            "
          >
            <v-col cols="12">
              <v-chip
                v-for="tag in faq.tags"
                :key="tag"
                :color="$store.getters.tag(tag).color"
              >
                {{ $store.getters.tag(tag).name }}
              </v-chip>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              v-html="compiled_texte"
              class="article-texte"
            ></v-col>
            <v-col cols="12" lg="6">
              <v-img
                v-if="faq.image"
                :src="faq.image"
                :width="$vuetify.breakpoint.lgAndUp ? '' : '600px'"
                max-width="100%"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialog = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-img>
</template>

<style scoped lang="scss">
$dimension: 300px;

.dialog-box {
  height: 100%;
}

.article-container {
  flex: 1 0 auto;
  border: 1px black solid;
  border-radius: 10px;
  margin: 5px;
  background-size: cover;
  padding: 0;
  position: relative;
  width: $dimension;
  padding-top: 5px;
  height: $dimension;
  @media screen and (min-width: 600px) {
    max-width: 24%;
  }
}

.article-container .article-titre {
  color: black;
  //background-color: lightgrey;
  border-radius: 10px;
  font-size: xx-large;
  display: none;
  text-align: center;
  padding: 10px;
  margin: 80px 5px 5px;
  @media screen and (max-width: 600px) {
    display: block;
    backdrop-filter: blur(5px);
    background-color: #f0f0f085;
    cursor: pointer;
  }
}

.article-container:hover {
}

.article-container:hover .article-titre {
  display: block;
  backdrop-filter: blur(5px);
  background-color: #f0f0f085;
  cursor: pointer;
}

.article-card {
  max-width: 60%;
}

.article-texte {
  height: 60vh;

  ::deep a {
    text-style: bold;
    color: inherit;
  }

  :deep(em) {
    font-style: normal;
    color: red;
  }
}

.article-texte-groupe {
}

.article-texte-groupe-mobile {
  flex-wrap: wrap-reverse;
}

.faq-image-mobile {
  width: 80vw;
}
</style>

<script>
import { marked } from "marked";

export default {
  name: "FaqQuestion",
  props: {
    faq: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    show() {
      this.dialog = true;
    },
  },
  computed: {
    compiled_texte() {
      return marked(this.faq.reponse);
    },
    image_style() {
      if (this.faq.image) {
        return { backgroundImage: `url(${this.faq.image})` };
      } else {
        return {};
      }
    },
  },
};
</script>
