<template>
  <!-- Calendly inline widget begin -->
  <div
    class="calendly-inline-widget"
    data-url="https://calendly.com/aneyaagency/ton-shooting-flash-offert?background_color=0f0f0f&text_color=ffffff&primary_color=ff1616"
    style="min-width: 320px"
  ></div>
  <!-- Calendly inline widget end -->
</template>

<style scoped lang="scss">
.calendly-inline-widget {
  overflow: hidden;
  height: fit-content !important;
}
</style>
<style lang="scss">
iframe {
  height: 700px !important;
}
</style>

<script>
export default {
  name: "ShootingCalendar",
  mounted() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    document.head.appendChild(recaptchaScript);
  },
};
</script>
