import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import Meta from "vue-meta";
import dayjs from "dayjs";
import VueCookies from "vue-cookies";
import { marked } from "marked";

marked.use({
  renderer: {
    heading(text, level) {
      return `<div class="md-titre-${level}">${text}</div>`;
    },
    link(href, title, text) {
      let link_href = href;
      if (!href.startsWith("http")) {
        let datas = href.split(":");
        let props = router.resolve({ name: datas[0] });
        if (datas[1]) {
          props = router.resolve({
            name: datas[0],
            params: { slug: datas[1] },
          });
        } else {
          props = router.resolve({
            name: datas[0],
          });
        }

        link_href = props.href;
      }
      return `<a href='${link_href}'>${text}</a>`;
    },
  },
});

const relativeTime = require("dayjs/plugin/relativeTime");

require("dayjs/locale/fr");

Vue.config.productionTip = false;
Vue.use(Meta);
Vue.use(VueCookies);

let filter = function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_SERVER;
Vue.prototype.$axios = axios;

dayjs.locale("fr");
dayjs.extend(relativeTime);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
