<template>
  <div
    class="portfolio-element"
    :style="image_style"
    @click="showOverlay = true"
  >
    {{ pfElement.texte }}
    <v-overlay v-if="isImage" :value="showOverlay" class="image-element">
      <v-img :src="pfElement.image" @click.stop="showOverlay = false"></v-img>
    </v-overlay>
  </div>
</template>

<style scoped>
.portfolio-element {
  border: red 1px solid;
  height: 100%;
  width: 300px;
  border-radius: 25px;
  padding: 5px;
}

.image-element {
  width: 100%;
}

.image-element :deep(.v-overlay__content) {
  width: 100%;
}
</style>

<script>
export default {
  name: "PortfolioElement",
  props: {
    pfElement: {
      type: Object,
      required: true,
    },
  },
  metaInfo: {
    title: "Portfolio",
  },
  data() {
    return {
      showOverlay: false,
    };
  },
  computed: {
    isText() {
      return this.pfElement.type === 2;
    },
    isImage() {
      return this.pfElement.type === 1;
    },
    image_style() {
      return {
        backgroundImage: `url(${this.pfElement.image}`,
        backgroundPosition: "center",
        backgroundClip: "contain",
      };
    },
  },
};
</script>
