<template>
  <div
    class="bulle-container bulle-image"
    :style="get_background_style"
    @click="gotoDetails"
  >
    <div class="texte-bulle">{{ pfItem.name }}</div>
  </div>
</template>

<style scoped lang="scss">
.bulle-image {
  background-position: center;
  background-size: cover;
}

.bulle-container .texte-bulle {
  display: none;
  font-weight: bold;
  font-size: xx-large;
  padding: 25px;
}

.bulle-container {
  width: 100%;
  height: 300px;
  background-color: lightgrey;
  border-radius: 25px;
  padding: 10px;
  margin: 0px;
  position: relative;
  border: white solid 2px;
}

.bulle-container:hover .texte-bulle {
  display: block;
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f085;
  cursor: pointer;
  border-radius: 25px;
}
</style>

<script>
export default {
  name: "PortfolioItem",
  props: {
    pfItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    gotoDetails() {
      this.$router.push({
        name: "portfolio-details",
        params: { pfslug: this.pfItem.slug, pfItem: this.pfItem },
      });
    },
  },
  computed: {
    get_background_style() {
      return {
        backgroundImage: `url(${this.pfItem.main_image})`,
      };
    },
  },
};
</script>
