<template>
  <v-app-bar app bottom hide-on-scroll dark dense tag="footer">
    <v-spacer></v-spacer>
    <div v-if="!$vuetify.breakpoint.mobile">
      <v-btn to="legal" class="nav-btn">Mentions Légales</v-btn>
      <v-btn to="siteplan" class="nav-btn">Plan du site</v-btn>
      <v-btn to="team" class="nav-btn">L'équipe</v-btn>
      <!-- <v-btn to="admin" class="nav-btn" exact>Admin</v-btn>-->
      <v-btn to="contact" class="nav-btn" exact>Contact</v-btn>
    </div>
    <v-spacer />
    <span>&copy; 2022 Aneya Agency</span>
  </v-app-bar>
</template>

<script>
export default {
  name: "BottomBar",
};
</script>
