<template>
  <v-app-bar app color="#0F0F0F" dark>
    <div class="d-flex align-center">
      <v-img
        alt="Aneya Logo"
        class="shrink mr-2 navbar-logo"
        contain
        src="../assets/logo/logo60x60.png"
        transition="scale-transition"
        max-width="60"
        :max-height="$vuetify.breakpoint.mobile ? '80%' : '100%'"
        @click="goHome"
      />
      <h2 class="navbar-title">Aneya Agency</h2>
    </div>

    <v-spacer></v-spacer>
    <v-menu v-if="$vuetify.breakpoint.mobile" right content-class="menu-bar">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="nav-btn" v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="link in links" :key="link.to">
          <v-btn :to="{ name: link.to }" class="nav-btn-mobile" exact>
            {{ link.name }}
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="nav-btn-mobile" to="legal">Mentions Légales</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="nav-btn-mobile" to="siteplan">Plan du site</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            v-if="!$store.getters.is_logged"
            :to="{ name: 'login' }"
            class="nav-btn-mobile"
            exact
          >
            Login
          </v-btn>
          <v-btn v-else to="admin" class="nav-btn-mobile" exact>Admin</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <div class="btn-bar" v-else>
      <v-btn
        v-for="link in links"
        :key="link.to"
        :to="{ name: link.to }"
        class="nav-btn"
        exact
      >
        {{ link.name }}
      </v-btn>
    </div>
  </v-app-bar>
</template>

<style scoped lang="scss">
.menu-bar {
}

.navbar-logo {
  cursor: pointer;
}

.navbar-title {
  margin: auto 0;
}

.btn-bar {
  //@extend .border;
  padding: 5px;
}

.nav-btn {
}

.nav-btn-mobile {
  width: 100%;
}
</style>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      links: [
        { to: "home", name: "Bienvenue" },
        { to: "prestations", name: "Mon accompagnement" },
        { to: "portfolio", name: "Nos projets" },
        { to: "actus", name: "Actualités" },
        // { to: "team", name: "L'équipe" },
        { to: "faq", name: "J'ai une question" },
        { to: "contact", name: "Contact" },
      ],
    };
  },
  methods: {
    goHome() {
      if (this.$route.name !== "home") {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>
