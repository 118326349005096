import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import PrestationsView from "@/views/PrestationsView";
import ActusView from "@/views/ActusView";
import TeamList from "@/views/TeamList";
import ContactForm from "@/views/ContactForm";
import LoginView from "@/views/LoginView";
import MentionsLegalesView from "@/views/MentionsLegalesView";
import SitePlan from "@/views/SitePlan";
import PortFolio from "@/views/PortfolioView";
import PortfolioDetail from "@/views/PortfolioDetail";
import store from "@/store";
import FaqView from "@/views/FaqView";
import ChangePasswordView from "@/views/ChangePasswordView";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "home", component: HomeView },
  { path: "/portfolio", name: "portfolio", component: PortFolio },
  {
    path: "/portfolio/:slug",
    name: "portfolio-details",
    props: true,
    component: PortfolioDetail,
  },
  {
    path: "/prestations/:slug?",
    name: "prestations",
    component: PrestationsView,
    props: true,
  },
  {
    path: "/actus/:slug?",
    name: "actus",
    props: true,
    component: ActusView,
  },
  { path: "/faq/:slug?", name: "faq", component: FaqView, props: true },
  { path: "/team", name: "team", component: TeamList },
  { path: "/contact", name: "contact", component: ContactForm },
  { path: "/admin/login", name: "login", component: LoginView },
  {
    path: "/admin/change-password",
    name: "change-password",
    component: ChangePasswordView,
  },
  { path: "/mentions", name: "legal", component: MentionsLegalesView },
  { path: "/siteplan", name: "siteplan", component: SitePlan },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/AdminView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.is_logged) next();
      else next({ name: "login" });
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
