<template>
  <v-carousel
    cycle
    continuous
    show-arrows-on-hover
    hide-delimiters
    hide-delimiter-background
    class="carousel-wrapper"
    max-height="600px"
    height="fit-content"
  >
    <info-carousel-item
      v-for="info in infos"
      :info="info"
      :key="info.id"
    ></info-carousel-item>
  </v-carousel>
</template>

<style scoped lang="scss">
.carousel-wrapper {
  width: 600px;
  max-height: 600px !important;
  margin: 0 auto;
  /*max-width: 600px;
  margin-left: auto;
  margin-right: auto;*/
  border: 2px white solid;
  border-radius: 25px;
}

@media screen and (max-width: 600px) {
  .carousel-wrapper {
    width: 90vw !important;
    max-height: 90vw !important;
  }
}

@media screen and (min-width: 1500px) {
  .carousel-wrapper {
    height: 600px !important;
  }
}

/*
.carousel-container {
  margin: 0 auto;
  //max-width: fit-content;
  //height: $height;
  position: relative;
}

.carousel-img {
  //max-height: $height;
  border-radius: 10px;
}*/

.carousel-titre {
  position: absolute;
  color: red;
  z-index: 10;
  top: 10%;
  left: 20%;
  font-weight: bolder;
}

.carousel-texte {
  position: absolute;
  color: green;
  z-index: 10;
  top: 50%;
  left: 20%;
  font-weight: bolder;
}
</style>

<script>
import InfoCarouselItem from "@/components/infos/InfoCarouselItem";

export default {
  name: "InfoCarousel",
  components: { InfoCarouselItem },
  data() {
    return {
      infos: [],
      carousel: null,
      currentImage: null,
    };
  },
  mounted() {
    this.$axios.get("/api/infos/published/").then((response) => {
      this.infos = response.data;
    });
  },
  methods: {
    get_image_style(url) {
      return {
        backgroundImage: `url(${url});`,
      };
    },
  },
};
</script>
