<template>
  <v-container>
    <h2>Nos Actualités</h2>
    <div class="actus__tags_selection">
      <select-tags v-model="selection"></select-tags>
    </div>
    <div class="actus-container">
      <actu-article
        v-for="actu in actus_filtered"
        :key="actu.id"
        :article="actu"
        :id="actu.slug"
      ></actu-article>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
.actus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  gap: 20px;
  //justify-content: space-evenly;
}
.actus__tags_selection {
  width: 50%;
  margin-left: auto;
}
</style>

<script>
import ActuArticle from "@/components/actus/ActuArticle";
import SelectTags from "@/components/tags/SelectTags.vue";

export default {
  name: "ActusView",
  metaInfo: {
    title: "Actualités",
  },
  components: { SelectTags, ActuArticle },
  data() {
    return {
      actus: [],
      actus_filtered: [],
      selection: [],
    };
  },
  mounted() {
    this.$axios.get("/api/actus/published/").then((response) => {
      this.actus = response.data;
      if (this.$route.params.slug) {
        this.$nextTick(() => {
          const it = this.$el.querySelector(`#${this.$route.params.slug}`); // document.getElementById(this.$route.params.actuslug);
          it.click();
        });
      }
    });
  },
  methods: {
    filter_actus() {
      this.actus_filtered = this.actus.filter((elt) => {
        if (elt.tags.length === 0) {
          return true;
        } else {
          return elt.tags.some((tag) => this.selection.includes(tag));
        }
      });
    },
  },
  watch: {
    selection() {
      this.filter_actus();
    },
    actus() {
      this.filter_actus();
    },
  },
};
</script>
