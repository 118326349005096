<template>
  <v-carousel-item>
    <!--    <div class="carousel-container" :style="get_image_style">
          &lt;!&ndash;<div class="carousel-titre">{{ info.titre }}</div>
          <div class="carousel-texte">{{ info.texte }}</div>&ndash;&gt;
        </div>-->
    <v-img
      :src="info.image"
      :alt="info.image_alt"
      lazy-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAJYAQMAAACEqAqfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAADUExURR4eHp+MsRwAAABDSURBVBgZ7cEBDQAAAMIg+6d+DjdgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADnArIgAAGh2+XoAAAAAElFTkSuQmCC"
    >
      <template v-slot:placeholder>
        <div class="carousel__placeholder">
          <v-progress-circular class="carousel__progress" indeterminate />
        </div>
      </template>
    </v-img>
  </v-carousel-item>
</template>

<style scoped lang="scss">
.carousel-container {
  background-size: contain;
  background-position: center;
  //max-height: 600px; //100%;
  //max-width: 600px; //100%;
  //min-height: 200px;
  //min-width: 200px;
  height: 100%;
  width: 100%;
}

.carousel-titre {
  color: black;
  font-weight: bolder;
  font-size: xxx-large;
  width: fit-content;
  padding: 5px;
  border-radius: 10px;
  margin: 5px auto;
  backdrop-filter: blur(5px);
  background-color: #f0f0f085;
}

.carousel-texte {
  color: green;
  width: fit-content;
  margin: 0 auto;
  font-weight: bolder;
  backdrop-filter: blur(5px);
  background-color: #f0f0f085;
  padding: 5px;
  border-radius: 10px;
}

.carousel__placeholder {
  margin: auto;
}

.carousel__progress {
  margin: auto;
}
</style>

<script>
export default {
  name: "InfoCarouselItem",
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
};
</script>
