<template>
  <li @click="click_link">
    <!--    <v-img
          :src="img"
          height="200"
          width="200"
          style="margin: 20px"
          @load="img_loaded"
          eager
        />-->

    <img :src="img" class="img-marquee" />
  </li>
</template>

<style scoped lang="scss">
.img-marquee {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
</style>

<script>
export default {
  name: "MarqueeElement",
  props: {
    img: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
      default: "/portfolio/",
    },
  },
  data() {
    return {
      already_mounted: false,
    };
  },
  created() {
    this.$parent.$emit("element-created");
  },
  mounted() {
    this.$parent.$emit("element-mounted");
  },
  methods: {
    img_loaded() {
      if (!this.already_mounted) {
        this.$parent.$emit("element-mounted");
      }
    },
    click_link() {
      console.log("go!!");
      this.$router.push({ path: this.link });
    },
  },
};
</script>
