<template>
  <v-container>
    <h2>Nos Prestations</h2>
    <p></p>
    <v-container class="prestation__title_bar">
      <div
        id="shootingoffert"
        class="prestation__title_box"
        :class="{
          prestation__title_box_active: currentView === 'shootings',
        }"
        @click="toggleView"
      >
        <div class="prestation__title">Shooting Offert</div>
      </div>
    </v-container>
    <transition :name="transitionName" mode="out-in">
      <v-container
        key="shootingoffert"
        class="presentation__contenus"
        v-if="currentView === 'shootingoffert'"
      >
        <shooting-calendar />
      </v-container>
    </transition>
    <v-container class="prestation__title_bar">
      <div
        id="micro-influence"
        class="prestation__title_box"
        :class="{
          prestation__title_box_active: currentView === 'micro-influence',
        }"
        @click="toggleView"
      >
        <div class="prestation__title">Micro-Influence</div>
      </div>
      <div
        id="influence"
        class="prestation__title_box"
        :class="{
          prestation__title_box_active: currentView === 'influence',
        }"
        @click="toggleView"
      >
        <div class="prestation__title">Influence</div>
      </div>
      <div
        id="ultra-influence"
        class="prestation__title_box"
        :class="{
          prestation__title_box_active: currentView === 'ultra-influence',
        }"
        @click="toggleView"
      >
        <div class="prestation__title">Ultra-Influence</div>
      </div>
      <div
        id="discover"
        class="prestation__title_box"
        :class="{
          prestation__title_box_active: currentView === 'discover',
        }"
        @click="toggleView"
      >
        <div class="prestation__title">Pack DISCOVER</div>
      </div>
      <div
        id="explorer"
        class="prestation__title_box"
        :class="{
          prestation__title_box_active: currentView === 'explorer',
        }"
        @click="toggleView"
      >
        <div class="prestation__title">Pack EXPLORER</div>
      </div>
    </v-container>
    <transition :name="transitionName" mode="out-in">
      <!-- Micro Influence -->
      <v-container
        key="micro-influence-content"
        class="presentation__contenus"
        v-if="currentView === 'micro-influence'"
      >
        <h4>Micro Influence</h4>
        <p>
          Je lance mon activité et je souhaite être accompagné.e pour créer mes
          premiers contenus. Je découvre...
        </p>
        <p>Un suivi sur 3 mois</p>
        <p>A partir de 229€</p>
        <v-container class="prestation__blocs">
          <div
            class="prestation__blocs__header"
            style="grid-column: 1; grid-row: 1"
          >
            <div>Niveau 1.</div>
            <div class="prestation__description__price">229€/Mois</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 2; grid-column: 1"
          >
            <div>
              <v-icon>mdi-white-balance-sunny</v-icon>
              1 SHOOTING PAR MOIS
            </div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 3; grid-column: 1 / span 2"
          >
            <div>1 SPOT VIDEO INFLUENCE</div>
            <div>Général avec les images du shooting</div>
          </div>

          <div
            class="prestation__blocs__header"
            style="grid-column: 2; grid-row: 1"
          >
            <div>Niveau 2.</div>
            <div class="prestation__description__price">408€/Mois</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 2; grid-column: 2"
          >
            <div>
              <v-icon>mdi-white-balance-sunny</v-icon>
              2 SHOOTING PAR MOIS
            </div>
          </div>

          <div
            class="prestation__blocs__header"
            style="grid-column: 3; grid-row: 1"
          >
            <div>Niveau 3.</div>
            <div class="prestation__description__price">687€/Mois</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-column: 3; grid-row: 2"
          >
            <div>
              <v-icon>mdi-white-balance-sunny</v-icon>
              3 SHOOTING PAR MOIS
            </div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-column: 3; grid-row: 3"
          >
            <div>3 SPOT VIDEO INFLUENCE</div>
            <div>Général avec les images du shooting</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-column: 3; grid-row: 4"
          >
            <div>Creation de contenus</div>
            <div>2 REELS offerts</div>
          </div>
        </v-container>
      </v-container>
      <!-- Influence -->
      <v-container
        key="influence-content"
        class="presentation__contenus"
        v-else-if="currentView === 'influence'"
      >
        <h4>Influence</h4>
        <p>
          Mon activité est lancée, je souhaite me propulser avec une identité
          visuelle forte, et un contenu régulier. JE DECOUVRE...
        </p>
        <p>Un suivi sur 6 mois</p>
        <p>A partir de 459€</p>
        <v-container class="prestation__blocs">
          <div
            class="prestation__blocs__header"
            style="grid-column: 1; grid-row: 1"
          >
            <div>Niveau 1.</div>
            <div class="prestation__description__price">459€/Mois</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 2; grid-column: 1"
          >
            <div>
              <v-icon>mdi-white-balance-sunny</v-icon>
              2 SHOOTINGS PAR MOIS
            </div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 3; grid-column: 1 / span 2"
          >
            <div>1 SPOT VIDEO INFLUENCE</div>
            <div>Par moi avec les images du shooting</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 4; grid-column: 1 / span 2"
          >
            <div>Création de contenu</div>
            <div>2 REELS par mois</div>
          </div>
          <div
            class="prestation__blocs__header"
            style="grid-column: 2; grid-row: 1"
          >
            <div>Niveau 2.</div>
            <div class="prestation__description__price">618€/Mois</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 2; grid-column: 2"
          >
            <div>
              <v-icon>mdi-white-balance-sunny</v-icon>
              3 SHOOTING PAR MOIS
            </div>
          </div>

          <div
            class="prestation__blocs__header"
            style="grid-column: 3; grid-row: 1"
          >
            <div>Niveau 3.</div>
            <div class="prestation__description__price">807€/Mois</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-column: 3; grid-row: 2"
          >
            <div>
              <v-icon>mdi-white-balance-sunny</v-icon>
              4 SHOOTING PAR MOIS
            </div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-column: 3; grid-row: 3"
          >
            <div>2 SPOTS VIDEO INFLUENCE</div>
            <div>Par mois</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-column: 3; grid-row: 4"
          >
            <div>Creation de contenus</div>
            <div>4 REELS par mois</div>
          </div>
        </v-container>
      </v-container>
      <!-- Ultra Influence -->
      <v-container
        key="ultra-influence"
        class="presentation__contenus"
        v-else-if="currentView === 'ultra-influence'"
      >
        <h4>Ultra Influence</h4>
        <p>
          J'ai un projet d'influence important et souhaite rencontrer l'équipe
          qui m'accompagnera dans tous mes projets. JE DECOUVRE...
        </p>
        <p>Un suivi sur 12 mois</p>
        <p>A partir de 1059€</p>
        <v-container class="prestation__blocs">
          <div
            class="prestation__blocs__header"
            style="grid-column: 1; grid-row: 1"
          >
            <div>Niveau 1.</div>
            <div class="prestation__description__price">1059€/Mois</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 2; grid-column: 1 / span 3"
          >
            <div>L'ACCOMPAGNEMENT INFLUENCE NIVEAU 3</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 3; grid-column: 1 / span 3"
          >
            <div>4 Créations de clip musical sur l'année</div>
          </div>
          <div
            class="prestation__blocs__content"
            style="grid-row: 4; grid-column: 2 / span 2"
          >
            <div>4 créations de VLOG sur l'année</div>
          </div>
          <div
            class="prestation__blocs__header"
            style="grid-column: 2; grid-row: 1"
          >
            <div>Niveau 2.</div>
            <div class="prestation__description__price">1598€/Mois</div>
          </div>

          <div
            class="prestation__blocs__header"
            style="grid-column: 3; grid-row: 1"
          >
            <div>Niveau 3.</div>
            <div class="prestation__description__price">1787€/Mois</div>
          </div>

          <div
            class="prestation__blocs__content"
            style="grid-column: 3; grid-row: 5"
          >
            <div>1 Spot vidéo spécial</div>
            <div>Par mois</div>
          </div>
        </v-container>
      </v-container>
      <!-- Pack Discover -->
      <v-container
        key="discover-content"
        class="presentation__contenus"
        v-else-if="currentView === 'discover'"
      >
        <h4>l'offre DISCOVER</h4>
        <p class="prestation__description__chapeau">
          En une séance, trois contenus et un mois d'influence
        </p>
        <p class="prestation__description__price">A partir de 279€</p>
        <p>Offre valable pour mon premier contrat avec Aneya.</p>
        <div class="prestation__description__grid_wrapper">
          <div
            class="prestation__description__grid_item"
            style="grid-column: 1; grid-row: 1"
          >
            <div class="prestation__description__titre">
              <v-icon>mdi-white-balance-sunny</v-icon>
              Séance shooting en extérieur ou Packshot
            </div>
            <div>2 spots, 40 photos retouchées</div>
          </div>
          <div
            class="prestation__description__grid_item"
            style="grid-column: 1; grid-row: 2"
          >
            <div class="prestation__description__titre">
              Création de contenus
            </div>
            <div>2 Reels Photo et Vidéo offerts</div>
          </div>
          <div
            class="prestation__description__grid_item"
            style="grid-column: 2; grid-row: 1 / span 2"
          >
            <div class="prestation__description__titre">
              Spot Vidéo influence
            </div>
            <div>1 min avec les images du shooting</div>
          </div>
        </div>
      </v-container>
      <!-- Pack Explorer -->
      <v-container
        key="explorer-content"
        class="presentation__contenus"
        v-else-if="currentView === 'explorer'"
      >
        <h4>Pack EXPLORER</h4>
        <p class="prestation__description__chapeau">
          Un lancement d'activité fort et qualitatif sur Deux mois
        </p>
        <p class="prestation__description__price">
          A partir de 279€/mois ou 519€
        </p>
        <div class="prestation__description__grid_wrapper">
          <div
            class="prestation__description__grid_item"
            style="grid-column: 1; grid-row: 1"
          >
            <div class="prestation__description__titre">
              <v-icon>mdi-white-balance-sunny</v-icon>
              2 séances shooting en extérieur ou Packshot
            </div>
            <div>2 spots, 30 photos retouchées</div>
          </div>
          <div
            class="prestation__description__grid_item"
            style="grid-column: 1; grid-row: 2"
          >
            <div class="prestation__description__titre">
              Création de contenus
            </div>
            <div>4 Reels Photo et Vidéo offerts</div>
          </div>
          <div
            class="prestation__description__grid_item"
            style="grid-column: 2; grid-row: 1 / span 2"
          >
            <div class="prestation__description__titre">
              2 spots Vidéo influence
            </div>
            <div>1 min avec les images du shooting</div>
          </div>
        </div>
      </v-container>
      <v-container v-else class="presentation__contenus">
        Pour voir vos possibilités de suivi, à long ou moyen terme, clique sur
        le pack qui t'intéresse...
      </v-container>
    </transition>
    <!-- A la carte -->
    <v-container class="prestation__title_bar">
      <div
        id="shootings"
        class="prestation__title_box"
        :class="{ prestation__title_box_active: currentView === 'shootings' }"
        @click="toggleView"
      >
        <div class="prestation__title">Shootings Photo & Vidéo</div>
      </div>
      <div
        id="reseauxsociaux"
        class="prestation__title_box"
        :class="{
          prestation__title_box_active: currentView === 'reseauxsociaux',
        }"
        @click="toggleView"
      >
        <div class="prestation__title">
          Création de contenus réseaux sociaux
        </div>
      </div>
      <div
        id="clipsmusicaux"
        class="prestation__title_box"
        :class="{
          prestation__title_box_active: currentView === 'clipsmusicaux',
        }"
        @click="toggleView"
      >
        <div class="prestation__title">Clips Musicaux</div>
      </div>
      <div
        id="creationgraph"
        class="prestation__title_box"
        :class="{
          prestation__title_box_active: currentView === 'creationgraph',
        }"
        @click="toggleView"
      >
        <div class="prestation__title">
          Identité visuelle & Création graphique
        </div>
      </div>
      <div
        id="siteweb"
        class="prestation__title_box"
        :class="{ prestation__title_box_active: currentView === 'siteweb' }"
        @click="toggleView"
      >
        <div class="prestation__title">Création site web</div>
      </div>
    </v-container>
    <transition :name="transitionName" mode="out-in">
      <v-container
        key="shootings"
        class="presentation__contenus"
        v-if="currentView === 'shootings'"
      >
        <!-- Shooting extérieur-->
        <div class="prestation__carte__content">
          <div class="prestation__carte__content__title">
            <v-icon>mdi-white-balance-sunny</v-icon>
            Shooting en extérieur et packshot
          </div>
          <div class="prestation__carte__content__price">A partir de 189€</div>
          <div class="prestation__carte__content__description">
            2 tenues, 20 photos retouchées
          </div>
          <div class="prestation__carte__content__description">
            Création Vidéo : +120€
          </div>
          <div class="prestation__carte__content__description">
            Double shoot : +90€
          </div>
        </div>
        <!-- Shooting studio -->
        <div class="prestation__carte__content">
          <div class="prestation__carte__content__title">
            Shooting en studio
          </div>
          <div class="prestation__carte__content__price">A partir de 219€</div>
          <div class="prestation__carte__content__description">
            2 tenues, 20 photos retouchées
          </div>
          <div class="prestation__carte__content__description">
            Création Vidéo : +120€
          </div>
          <div class="prestation__carte__content__description">
            Double shoot : +90€
          </div>
        </div>
        <!-- Shooting Activité -->
        <div class="prestation__carte__content">
          <div class="prestation__carte__content__title">
            Shooting en Activité
          </div>
          <div class="prestation__carte__content__price">A partir de 199€</div>
          <div class="prestation__carte__content__description">
            2 tenues, 20 photos retouchées
          </div>
          <div class="prestation__carte__content__description">
            Création Vidéo : +120€
          </div>
          <div class="prestation__carte__content__description">
            Double shoot : +90€
          </div>
        </div>
      </v-container>
      <v-container
        key="reseauxsociaux"
        class="presentation__contenus"
        v-else-if="currentView === 'reseauxsociaux'"
      >
        <!-- Conception VLOG Youtube -->
        <div class="prestation__carte__content">
          <div class="prestation__carte__content__title">
            Conception VLOG Youtube
          </div>
          <div class="prestation__carte__content__price">A partir de 519€</div>
          <div class="prestation__carte__content__description">7 à 10 min</div>
          <div class="prestation__carte__content__description">
            2 demi journées de reportage et images de Shooting
          </div>
          <div class="prestation__carte__content__description">
            Teaser Réseaux
          </div>
          <div class="prestation__carte__content__description">
            30 photos retouchées
          </div>
        </div>
        <!-- Création Réseaux -->
        <div class="prestation__carte__content">
          <div class="prestation__carte__content__title">Création Réseaux</div>
          <div class="prestation__carte__content__price">A partir de 59€</div>
          <div class="prestation__carte__content__description">
            Montage 2 REELS, Tik Tok, Shorts, 30 sec
          </div>
          <div class="prestation__carte__content__description">
            REEL Supplementaire : +29€
          </div>
        </div>
      </v-container>
      <v-container
        key="clipsmusicaux"
        class="presentation__contenus"
        v-else-if="currentView === 'clipsmusicaux'"
      >
        <!-- Creation Clip musical-->
        <div class="prestation__carte__content">
          <div class="prestation__carte__content__title">
            Création de clip musical
          </div>
          <div class="prestation__carte__content__price">A partir de 429€</div>
          <div class="prestation__carte__content__description">
            1 jour de tournage, 3 min. Teaser réseaux
          </div>
          <div class="prestation__carte__content__description">
            Jour de tournage supplémentaire : +169€
          </div>
          <div class="prestation__carte__content__description">
            Shooting photo : +169€
          </div>
          <div class="prestation__carte__content__description">
            Cover album : +69€
          </div>
        </div>
      </v-container>
      <v-container
        key="creationgraph"
        class="presentation__contenus"
        v-else-if="currentView === 'creationgraph'"
      >
        <!-- Création Charte graphique -->
        <div class="prestation__carte__content">
          <div class="prestation__carte__content__title">
            Création charte graphique
          </div>
          <div class="prestation__carte__content__price">A partir de 89€</div>
          <div class="prestation__carte__content__description">
            Logo, charte couleurs, identité visuelle, police et titres
          </div>
          <div class="prestation__carte__content__description">
            Support visuel
          </div>
          <div class="prestation__carte__content__description">sur devis</div>
        </div>
        <!-- Community management -->
        <div class="prestation__carte__content">
          <div class="prestation__carte__content__title">
            Community management
          </div>
          <div class="prestation__carte__content__description">
            Création de contenu réseaux à partir de visuels existants et
            création graphique, Publication sur tous supports réseaux et veille
            stratégique
          </div>
          <div class="prestation__carte__content__price">
            A partir de 350€/mois
          </div>

          <div class="prestation__carte__content__description">
            Charte graphique réseaux : 49€
          </div>
        </div>
      </v-container>
      <v-container
        key="siteweb"
        class="presentation__contenus"
        v-else-if="currentView === 'siteweb'"
      >
        <!-- Création de site web -->
        <div class="prestation__carte__content">
          <div class="prestation__carte__content__title">
            Création de site web
          </div>
          <div class="prestation__carte__content__description">
            Création de site web vitrine à 5 pages principales, Page d'accueil,
            Page contact, Pages légales et Pages de contenu.
          </div>
          <div class="prestation__carte__content__price">A partir de 350€</div>
          <div class="prestation__carte__content__description">
            Charte graphique : 59€
          </div>
        </div>
      </v-container>
    </transition>
  </v-container>
</template>

<!--suppress CssInvalidFunction -->
<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.prestation__title_bar {
  display: flex;
  flex-wrap: wrap;
  background-color: #1e1e1e;
  padding-top: 20px;

  margin-top: 5px;

  &:first-of-type {
    border-radius: 25px 25px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 25px 25px;
  }
}

.prestation__title_box {
  @extend .border;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  //flex-grow: 1;
  display: flex;
  background-color: #1e1e1e;
  cursor: pointer;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .prestation__title_box {
    height: 80px;
  }
}

.prestation__title_box {
}

.prestation__title_box_active {
  color: red;
}

.prestation__title {
  margin: auto;
  pointer-events: none;
  text-align: center;
}

.presentation__contenus {
  max-height: 1000px;
  background-color: #1e1e1e;
}

.prestation__blocs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  grid-auto-rows: minmax(100px, auto);
}

.prestation__blocs__header {
  @extend .border;
  display: flex;
  flex-direction: column;

  div {
    margin: auto;
  }
}

.prestation__blocs__content {
  @extend .border;
  display: flex;
  flex-direction: column;

  div {
    margin: auto;
  }
}

.prestation__description {
  @extend .border;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.prestation__description > div {
  display: block;
  margin: 10px;
}

.prestation__description__price {
}

.prestation__description__grid_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.prestation__description__grid_item {
  @extend .border;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
}

.prestation__description__grid_item > div {
  text-align: center;
}

.prestation__description__titre {
  font-size: 1.2em;
}

.prestation__description__chapeau {
}

.prestation__carte {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  grid-auto-rows: minmax(100px, auto);
}

.prestation__carte__content {
  @extend .border;
  display: flex;
  flex-direction: column;

  div {
    margin: auto;
  }

  .prestation__carte__content__title {
    color: red;
  }

  .prestation__carte__content__price {
  }

  .prestation__carte__content__description {
  }
}

// EFFECT FADE
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 1s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// EFFECT SCALE

.scale-enter-active,
.scale-leave-active {
  transition: transform 1s ease;
  transform: scaleY(1);
  transform-origin: top;
}

.scale-enter,
.scale-leave-to {
  transform: scaleY(0);
}

// EFFECT CLIP
.clip-enter-active,
.clip-leave-active {
  //position: absolute;
  clip: rect(auto, auto, 0, auto);
  transition: clip 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.clip-enter,
.clip-leave-to {
  clip: rect(auto, auto, 10rem, auto);
}

// Effect height
.height-enter-active,
.height-leave-active {
  transition: max-height 1s;
  max-height: 1000px;
  overflow-y: clip;
}

.height-enter,
.height-leave-to {
  max-height: 0;
}
</style>

<script>
import ShootingCalendar from "@/components/ShootingCalendar.vue";

export default {
  name: "PrestationsView",
  components: { ShootingCalendar },
  metaInfo: {
    title: "Nos prestations",
  },
  props: {
    slug: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      currentView: "",
      views: {},
      transitionName: "height",
    };
  },
  mounted() {
    if (this.slug) {
      this.currentView = this.slug;
    }
  },
  methods: {
    toggleView(event) {
      if (this.currentView === event.srcElement.id) {
        this.currentView = "";
      } else {
        this.currentView = event.srcElement.id;
      }
    },
  },
};
</script>
