<template>
  <div>
    <v-select
      class="tags__select__widget"
      label="Tags"
      v-model="selected"
      :items="$store.getters.tags"
      item-value="id"
      item-text="name"
      multiple
      chips
      deletable-chips
    >
      <template v-slot:prepend-item>
        <v-list-item ripple @mousedown.prevent @click="toggle">
          <v-list-item-action>
            <v-icon :color="selected.length > 0 ? 'indigo-darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sélectionner tout...</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-slot:selection="{ item }">
        <v-chip :color="item.color" close @click:close="removeSelection(item)">
          {{ item.name }}
        </v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "SelectTags",
  props: {
    selection: {
      type: [Array, undefined],
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.selection,
    };
  },
  mounted() {
    this.toggle();
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.all_selected) {
          this.selected = [];
        } else {
          this.selected = [];
          this.$store.getters.tags.forEach((cat) => this.selected.push(cat.id));
        }
      });
    },
    removeSelection(item) {
      const index = this.selected.findIndex((elt) => elt === item.id);
      this.selected.splice(index, 1);
    },
  },
  computed: {
    all_selected() {
      return this.selected.length === this.$store.getters.tags.length;
    },
    some_selected() {
      return this.selected.length > 0 && !this.all_selected;
    },
    icon() {
      if (this.all_selected) return "mdi-close-box";
      if (this.some_selected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
  },
};
</script>

<style scoped></style>
