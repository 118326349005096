<template>
  <div ref="ribbon" class="corner-ribbon__inner">
    <!-- The ribbon -->
    <div ref="ribbontext" class="corner-ribbon__ribbon">
      <div :class="textClass">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.corner-ribbon {
  position: relative;
}

.corner-ribbon__inner {
  --ribbon-offset-negative: 0px;
  --ribbon-offset: 0px;
  /* Displayed at the top left corner */
  left: 0px;
  position: absolute;
  top: 0px;

  /* Size */
  height: 100%; //7rem;
  width: 100%; //7rem;

  /* Hide the part of its children which is displayed outside */
  overflow: hidden;
  display: flex;
}

.corner-ribbon__ribbon {
  /* Position */
  //left: 1rem;
  position: absolute;
  //top: 1rem;

  /* Size */
  height: var(--ribbon-height);
  width: 30rem;

  /* Displayed diagonally */
  //translate(-(var(--ribbon-offset)), var(--ribbon-offset))
  transform: translate((var(--ribbon-offset-negative)), var(--ribbon-offset))
    rotate(-45deg);
  transform-origin: center;

  /* Background color */
  background-color: var(--background-color);
  color: var(--text-color);

  /* Centerize the text content */
  text-align: center;
}
</style>

<script>
export default {
  name: "RibbonComponent",
  props: {
    textcolor: {
      type: String,
      default: "red",
    },
    backgroundColor: {
      type: String,
      default: "#d1d5db",
    },
    text: {
      type: String,
      default: "Nouveau",
    },
    ribbonHeight: {
      type: String,
      default: "1.5rem",
    },
    textClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ribbonWidth: 0,
    };
  },
  created() {
    this.$refs.ribbon.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {},
  mounted() {
    this.$refs.ribbon.style.setProperty("--text-color", this.textcolor);
    this.$refs.ribbon.style.setProperty(
      "--background-color",
      this.backgroundColor
    );
    this.$refs.ribbon.style.setProperty("--ribbon-height", this.ribbonHeight);
    this.resizeHandler();
  },
  methods: {
    resizeHandler() {
      const dim = this.$refs.ribbontext.getBoundingClientRect();
      console.log(dim);
      this.ribbonWidth = dim.width;
    },
  },
  computed: {
    ribbonSize() {
      return {
        height: `${this.ribbonWidth}px`,
        width: `${this.ribbonWidth}px`,
      };
    },
    ribbonPosition() {
      return {
        transform: `translate(-${this.clientWidth / 3}px, ${
          this.clientWidth / 3
        }px) rotate(-45deg);`,
      };
    },
  },
  watch: {
    ribbonWidth() {
      this.$refs.ribbontext.style.setProperty(
        "--ribbon-offset",
        `${this.ribbonWidth / 3}px`
      );
      this.$refs.ribbontext.style.setProperty(
        "--ribbon-offset-negative",
        `-${this.ribbonWidth / 3}px`
      );
    },
  },
};
</script>
