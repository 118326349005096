<template>
  <v-container>
    <h2>Foire aux questions</h2>
    <div class="faq__tags_selection">
      <select-tags v-model="selection"></select-tags>
    </div>
    <div class="faq-container">
      <faq-question
        v-for="faq in filtered_faqs"
        :key="faq.id"
        :faq="faq"
        :ref="faq.slug"
      ></faq-question>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
.faq-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  //justify-content: space-evenly;
}
</style>

<script>
import FaqQuestion from "@/components/faq/FaqQuestion";
import SelectTags from "@/components/tags/SelectTags.vue";

export default {
  name: "FaqView",
  metaInfo: {
    title: "J'ai une question",
  },
  components: { SelectTags, FaqQuestion },
  props: {
    slug: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      faqs: [],
      filtered_faqs: [],
      selection: [],
    };
  },
  mounted() {
    this.$axios.get("/api/faq/").then((response) => {
      this.faqs = response.data;
      if (this.slug) {
        this.$refs[this.slug][0].show();
      }
    });
  },
  methods: {
    filter_faqs() {
      this.filtered_faqs = this.faqs.filter((elt) => {
        if (elt.tags.length === 0) {
          return true;
        } else {
          return elt.tags.some((tag) => this.selection.includes(tag));
        }
      });
    },
  },
  watch: {
    selection() {
      this.filter_faqs();
    },
    faqs() {
      this.filter_faqs();
    },
  },
};
</script>
