<template>
  <v-container>
    <h2>Nous contacter</h2>
    <v-container class="full-container">
      <v-row>
        <v-col cols="12" md="6" lg="6" class="infos-container">
          <div>Soit Ici</div>
          <div class="contact-item">
            <v-icon>mdi-cellphone</v-icon>
            06.06.06.06.06
          </div>
          <div class="contact-item">
            <v-icon>mdi-at</v-icon>
            aneya@aneya.com
          </div>
          <div class="contact-item">
            <v-icon>mdi-instagram</v-icon>
            @aneya_agency
          </div>
          <div>Ou alors tu remplis ce formulaire, et on te recontactera</div>
        </v-col>
        <v-col cols="12" md="6" lg="6" class="contact-container">
          <v-snackbar v-model="showSnackBar">
            {{ resultMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="showSnackBar = false"
                >Fermer
              </v-btn>
            </template>
          </v-snackbar>
          <v-form class="contact-form">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="name" label="Nom" :rules="nameRules" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="email"
                  label="Adresse E-mail"
                  :rules="emailRules"
                />
              </v-col>
            </v-row>
            <v-text-field v-model="titre" label="Sujet" />
            <v-textarea v-model="texte" label="Texte" />
            <v-row>
              <v-spacer></v-spacer>

              <v-btn color="primary" @click="send_email">Envoyer</v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.full-container {
  border: 2px white solid;
  border-radius: 10px;
  padding: 10px;
  width: 80%;
}

.infos-container {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  margin: 5% 0;
}

.contact-item {
  margin-left: 10px;
}

.contact-container {
}

.contact-form {
  margin: 5px;
  padding-bottom: 20px;
}
</style>

<script>
export default {
  name: "ContactForm",
  metaInfo: {
    title: "Contact",
  },
  props: {
    reason: {
      type: String,
      default: "contact",
    },
  },
  data() {
    return {
      name: "",
      nameRules: [(v) => !!v || "Merci de remplir votre nom"],
      titre: "",
      texte: "",
      email: "",
      emailRules: [
        (v) =>
          !!v ||
          "Merci de remplir avec votre adresse mail, Nous ne pourrons pas vous répondre",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Adresse mail invalide",
      ],
      showSnackBar: false,
      resultMessage: "",
    };
  },
  methods: {
    send_email() {
      this.$axios
        .post("/api/sendmail", {
          name: this.name,
          titre: `ANEYA WEBSITE : ${this.reason}: ${this.titre}`,
          texte: this.texte,
          email: this.email,
        })
        .then((response) => {
          if (response.status === 200) {
            this.resultMessage = "Votre message a été envoyé avec succès";
            this.showSnackBar = true;
          }
        });
    },
  },
};
</script>
