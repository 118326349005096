<template>
  <v-container fluid>
    <v-row class="home-row">
      <v-col cols="12" lg="6" class="home-element">
        <v-img
          :src="require('@/assets/logo/logo200x200.png')"
          width="200px"
          class="home-img"
          contain
          max-height="200px"
        ></v-img>
        <h1>Aneya Agency<br />Agence de communication créative</h1>
        <p class="texte-description">
          Développe ton activité de création<br />et inspire avec des images
          fortes.
        </p>
        <div class="home-img">
          <v-img
            :src="require('../assets/logo/instagram75x75.png')"
            width="75px"
            class="home-img"
            max-height="75px"
          ></v-img>
        </div>
        <div class="home-img">
          <v-btn
            :to="{ name: 'contact', reason: 'Lancer Idée' }"
            class="home-btn"
            >Je lance mon idée
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" lg="6" class="home-element">
        <keep-alive>
          <info-carousel />
        </keep-alive>
      </v-col>
    </v-row>
    <h2 class="texte-slogan">Affirme ton univers, vis de ton projet.</h2>
    <v-row class="home-row">
      <v-col cols="12" lg="8">
        <p>
          Tu es artiste, sportif.ve, créateur.rice, entrepreneur.e et tu
          souhaites
          <em class="text-emphase"> être reconnu.e pour ce que tu proposes</em>?
        </p>
        <p>
          Aneya t'accompagne dans ton projet pour créer une
          <em class="text-emphase">identité visuelle forte</em>, engager et
          agrandir ta communauté?
        </p>

        <ul>
          <li>Shootings photo & vidéo,</li>
          <li>spots,</li>
          <li>clips,</li>
          <li>conception de vlogs,</li>
          <li>création graphique,</li>
          <li>création de contenus pour les réseaux sociaux,</li>
          <li>community management...</li>
        </ul>
        <p>
          Quel que soit ton besoin et la durée de l'accompagnement que tu
          souhaites, Aneya s'adapte à ta demande.<br />Comment? on t'explique
          ici ...
        </p>
        <v-btn class="home-btn" :to="{ name: 'prestations' }"
          >Je choisis ma prestation
        </v-btn>
      </v-col>
    </v-row>
    <div class="home-row">
      <marquee-view />
    </div>
  </v-container>
</template>

<style scoped lang="scss">
.home-row {
  //display: flex;
  //flex-wrap: wrap;
  margin: 10px;
}

.home-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.home-img {
  margin: 0 auto;
}

.texte-slogan {
  font-weight: bold;
  color: red;
  text-align: center;
  margin: 25px 0;
}

.texte-presentation {
  width: 80%;
  margin: 0 auto;
}

.texte-description {
  font-style: italic;
}

.text-emphase {
  font-style: normal;
  text-color: red;
}

.home-btn {
}
</style>

<script>
import InfoCarousel from "@/components/infos/InfoCarousel";
import MarqueeView from "@/components/marquee/MarqueeView";

export default {
  name: "HomeView",
  metaInfo: {
    title: "Accueil",
  },
  components: { InfoCarousel, MarqueeView },
};
</script>
