<template>
  <v-container>
    <v-form @submit.prevent="sendToken" ref="newPassword">
      <v-text-field
        v-model="password1"
        label="Mot de passe"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        :rules="passwordRules"
        outlined
        dark
      ></v-text-field>
      <v-text-field
        v-model="password2"
        label="Mot de passe"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        :rules="confirmPasswordRules"
        outlined
        dark
      ></v-text-field>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "ChangePasswordView",
  data() {
    return {
      showPassword: false,
      password1: "",
      password2: "",
      token: this.$route.params.token,
      passwordRules: [
        (v) => !!v || "Le mot de passe est nécessaire",
        (v) => v && v.length > 8,
      ],
      confirmPasswordRules: [
        (v) => !!v || "Confirmer le mot de passe",
        (v) => v === this.password1 || "Les 2 doivent être identiques",
      ],
      tokenInvalid: false,
    };
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    checkToken() {
      this.$axios
        .post("/reset-password/validate_token/", { token: this.token })
        .then((response) => {
          this.tokenInvalid = !(response.status === 200);
        });
    },
    sendToken() {
      if (this.$refs.newPassword.validate()) {
        this.$axios
          .post("/reset-password/confirm/", {
            token: this.token,
            password: this.password1,
          })
          .then((response) => {
            if (response.status === 200) {
              this.$router.push({ name: "login" });
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>
