import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const loginModule = {
  state: {
    access_token: "",
    refresh_token: "",
    username: "",
    password: "",
    login_error_message: "",
  },
  getters: {
    has_credential(state) {
      return (
        ((state.username.length > 0) & (state.password.length > 0)) |
        (state.access_token.length > 0)
      );
    },
    is_logged(state) {
      return state.access_token.length > 0;
    },
    login_error_message(state) {
      return state.login_error_message;
    },
    get_token(state) {
      return state.access_token;
    },
    get_headers(state) {
      return {
        headers: {
          Authorization: `Bearer ${state.access_token}`,
        },
      };
    },
  },
  mutations: {
    set_credential(state, payload) {
      state.username = payload.username;
      state.password = payload.password;
    },
    set_tokens(state, payload) {
      state.access_token = payload.access;
      state.refresh_token = payload.refresh;
      if (payload.refresh) {
        Vue.$cookies.set("aneya-refresh", payload.refresh, "6d");
      } else {
        Vue.$cookies.remove("aneya-refresh");
      }
    },
    set_refresh_token(state, payload) {
      state.refresh_token = payload;
    },
    set_login_error_message(state, payload) {
      state.login_error_message = payload;
    },
  },
  actions: {
    do_login(context) {
      return new Promise((resolve) => {
        context.state.login_error_message = "";
        if (context.getters.has_credential) {
          axios
            .post("/token/", {
              username: context.state.username,
              password: context.state.password,
            })
            .then((response) => {
              if (response.status === 200) {
                context.commit("set_tokens", response.data);
                resolve(true);
              } else {
                context.commit("set_tokens", { access: "", refresh: "" });
                resolve(false);
              }
            })
            .catch((reason) => {
              context.commit(
                "set_login_error_message",
                reason.response.data.detail
              );
              resolve(false);
            });
        }
      });
    },
    do_refresh(context) {
      return new Promise((resolve) => {
        axios
          .post("/token/refresh/", { refresh: context.state.refresh_token })
          .then((response) => {
            if (response.status === 200) {
              context.commit("set_tokens", response.data);
              resolve(true);
            } else {
              context.commit("set_tokens", { access: "", refresh: "" });
              resolve(false);
            }
            resolve(false);
          });
      });
    },
  },
};

export default new Vuex.Store({
  state: {
    user_new: true,
    tags: [],
  },
  getters: {
    is_user_new(state) {
      return state.user_new;
    },
    tags(state) {
      return state.tags;
    },
    tag: (state) => (id) => {
      const tag = state.tags.find((elt) => elt.id === id);
      if (tag) {
        return tag;
      } else {
        return { name: "Aucun", color: "#555555", id: 0 };
      }
    },
  },
  mutations: {
    user_come(state) {
      state.user_new = false;
    },
  },
  actions: {
    load_tags(context) {
      return new Promise((resolve) => {
        axios
          .get("/api/categories/")
          .then((response) => {
            if (response.status === 200) {
              context.state.tags = response.data;
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => resolve(false));
      });
    },
  },
  modules: {
    auth: loginModule,
  },
});
