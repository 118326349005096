<template>
  <v-container class="portfolio-detail-container">
    <h2>Projet : {{ pf.name }}</h2>
    <div class="portfolio-item-row">
      <div class="portfolio-item" :style="main_image"></div>
      <div class="portfolio-item">
        {{ pf.description }}
      </div>
      <portfolio-element
        v-for="elt in pf.elements"
        :key="elt.id"
        :pf-element="elt"
      ></portfolio-element>
    </div>
    <v-btn fab fixed left :to="{ name: 'portfolio' }" class="portfolio__fab"
      ><v-icon>mdi-arrow-left-circle</v-icon></v-btn
    >
  </v-container>
</template>

<style scoped lang="scss">
.portfolio__fab {
  left: 100px;
}
.portfolio-detail-container {
}

.portfolio-item-row {
  margin-top: 10px;
  height: 300px;
  gap: 10px;
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.portfolio-item {
  height: 100%;
  width: 300px;
  border: blue solid 1px;
  border-radius: 25px;
  padding: 10px;
}
</style>

<script>
import PortfolioElement from "@/components/portfolio/PortfolioElement";

export default {
  name: "PortfolioDetail",
  components: { PortfolioElement },
  props: {
    pfItem: {
      type: Object,
      required: false,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pf: {},
    };
  },
  created() {
    if (!this.pfItem) {
      this.$axios.get(`/api/portfolio/${this.slug}/`).then((response) => {
        this.pf = response.data;
      });
    } else {
      this.pf = this.pfItem;
    }
  },
  computed: {
    main_image() {
      if (this.pf.main_image) {
        return {
          backgroundImage: `url(${this.pf.main_image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        };
      } else {
        return {};
      }
    },
  },
};
</script>
