<template>
  <v-container></v-container>
</template>

<script>
export default {
  name: "MentionsLegalesView",
  metaInfo: {
    title: "Mentions Légales",
  },
};
</script>

<style scoped></style>
