<template>
  <v-container>
    <h2>Ils nous ont fait confiance!</h2>
    <div class="pf-row">
      <div class="bulle-container">
        <div class="texte-bulle">
          Tu veux voir ton projet dans cette liste ? c'est par
        </div>
        <v-btn
          :to="{ name: 'contact', reason: 'Portfolio' }"
          class="portfolio-btn"
          width="75%"
          >ici
        </v-btn>
      </div>
      <portfolio-item
        v-for="pf in portfolio"
        :key="pf.id"
        :pf-item="pf"
      ></portfolio-item>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
.pf-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.bulle-container {
  @extend .border;
  width: 300px;
  height: 300px;
  background-color: #1e1e1e;
  padding: 10px;
  margin: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.bulle-container .texte-bulle {
  padding-top: 5px;
  font-weight: bold;
  font-size: larger;
}

.portfolio-btn {
  margin: auto;
}

//:deep(.bulle-container)::after {
//  content: "";
//  display: block;
//  padding-bottom: 90%;
//}
</style>

<script>
import PortfolioItem from "@/components/portfolio/PortfolioItem";

export default {
  name: "PortfolioView",
  components: { PortfolioItem },
  metaInfo: {
    title: "Portfolio",
  },
  data() {
    return {
      portfolio: [],
    };
  },
  mounted() {
    this.$axios.get("/api/portfolio/").then((response) => {
      this.portfolio = response.data;
    });
  },
};
</script>
