<template>
  <div class="marquee" id="marquee" ref="marquee">
    <ul class="marquee-content">
      <slot
        @element-created="elt_created"
        @element-mounted="elt_mounted"
      ></slot>
    </ul>
  </div>
</template>

<style>
#marquee {
  --marquee-width: 80vw;
  --marquee-height: 20vh;
  /*--marquee-elements: 12; /* defined with JavaScript */
  --marquee-elements-displayed: 5;
  --marquee-element-width: calc(
    var(--marquee-width) / var(--marquee-elements-displayed)
  );
  --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
}

.marquee {
  margin: 5px;
  width: var(--marquee-width);
  height: var(--marquee-height);
  background-color: #111;
  color: #eee;
  overflow: hidden;
  position: relative;
}

.marquee:before,
.marquee:after {
  position: absolute;
  top: 0;
  width: 10rem;
  height: 100%;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
  background: linear-gradient(to right, #111 0%, transparent 100%);
}

.marquee:after {
  right: 0;
  background: linear-gradient(to left, #111 0%, transparent 100%);
}

.marquee .marquee-content {
  list-style: none;
  height: 100%;
  display: flex;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
}

.marquee:hover .marquee-content {
  animation-play-state: paused;
}

/* .marquee-content:hover {
  animation-play-state: paused;
} */
@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-1 * var(--marquee-element-width) * var(--marquee-elements))
    );
  }
}

.marquee-content > li {
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  flex-shrink: 0;
  width: var(--marquee-element-width);
  max-height: 100%;
  font-size: calc(var(--marquee-height) * 3 / 4); /* 5rem; */
  white-space: nowrap;
  border-radius: 25px;
  cursor: pointer;
}

.marquee-content li > img {
  width: 100%;
  /* height: 100%; */
  border: 2px solid #eee;
  border-radius: 25px;
}

@media (max-width: 600px) {
  #marquee {
    --marquee-width: 100vw;
    --marquee-height: 16vh;
  }

  .marquee:before,
  .marquee:after {
    width: 5rem;
  }
}

@media (max-width: 600px) {
  #marquee {
    --marquee-elements-displayed: 1;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  #marquee {
    --marquee-elements-displayed: 3;
  }
}
</style>

<script>
export default {
  name: "MarqueeContainer",
  props: {
    width: {
      type: String,
      default: "80vw",
    },
    height: {
      type: String,
      default: "20vh",
    },
  },
  data() {
    return {
      nbCreated: 0,
      nbMounted: 0,
    };
  },
  created() {
    this.$on("element-created", () => {
      this.nbCreated++;
    });
    this.$on("element-mounted", this.elt_mounted);
  },
  mounted() {
    this.$refs.marquee.style.setProperty("--marquee-width", this.width);
    this.$refs.marquee.style.setProperty("--marquee-height", this.height);
  },
  methods: {
    elt_created() {
      this.nbCreated++;
    },
    elt_mounted() {
      this.nbMounted++;
      if (this.nbMounted === this.nbCreated) {
        this.$nextTick(this.animate);
      }
    },
    animate() {
      const root = this.$refs.marquee; //document.documentElement;

      const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue(
        "--marquee-elements-displayed"
      );
      const marqueeContent = document.querySelector("ul.marquee-content");

      root.style.setProperty(
        "--marquee-elements",
        marqueeContent.children.length
      );

      for (let i = 0; i < marqueeElementsDisplayed; i++) {
        marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
      }
    },
  },
};
</script>
