<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <nav-bar></nav-bar>
    <generique-accueil v-show="show_generique"></generique-accueil>
    <v-main v-show="!show_generique" :class="mainContainerClasses">
      <v-img
        :src="require('@/assets/logo/logo150x150.png')"
        width="150px"
        class="main-logo"
        v-show="show_logo"
      ></v-img>
      <router-view />
    </v-main>
    <bottom-bar></bottom-bar>
  </v-app>
</template>

<style scoped lang="scss">
.main-content {
  width: 80%;
  margin: 0 auto;
}

.main-content-mobile {
  width: 100%;
}

.main-logo {
  margin: 0 auto;
}
</style>

<script>
import NavBar from "@/components/NavBar";
import BottomBar from "@/components/BottomBar";
import GeneriqueAccueil from "@/components/GeneriqueAccueil";

export default {
  name: "App",
  components: { GeneriqueAccueil, BottomBar, NavBar },
  data: () => ({
    //
  }),
  metaInfo: {
    titleTemplate: "ANEYA | %s",
    meta: [
      { charset: "utf-8" },
      {
        httpEquiv: "Content-Type",
        content: "text/html; charset=utf-8",
      },
      {
        name: "viewport",
        content: "width=device-width,initial-scale=1.0",
      },
      {
        name: "description",
        content: "Aneya Agency",
      },
    ],
  },
  mounted() {
    this.$store.dispatch("load_tags");
  },
  computed: {
    mainContainerClasses() {
      return this.$vuetify.breakpoint.mobile
        ? "main-content-mobile"
        : "main-content";
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    show_generique() {
      return !!(
        (this.$route.name === "home") &
        this.$store.getters.is_user_new
      );
    },
    show_logo() {
      return !(this.$route.name === "home");
    },
  },
};
</script>
