<template>
  <v-container>
    <h2>Notre équipe</h2>
  </v-container>
</template>

<script>
export default {
  name: "TeamList",
  metaInfo: {
    title: "Notre équipe",
  },
};
</script>

<style scoped></style>
