<template>
  <marquee-container width="100%" height="250px">
    <marquee-element
      v-for="info in infos"
      :key="info.id"
      :img="info.main_image"
      :link="'/portfolio/' + info.slug"
    >
    </marquee-element>
  </marquee-container>
</template>

<script>
import MarqueeContainer from "@/components/marquee/MarqueeContainer";
import MarqueeElement from "@/components/marquee/MarqueeElement";

export default {
  name: "MarqueeView",
  components: { MarqueeContainer, MarqueeElement },
  data() {
    return {
      infos: [],
    };
  },
  mounted() {
    this.$axios.get("/api/portfolio/").then((response) => {
      this.infos = response.data;
    });
  },
};
</script>

<style scoped></style>
