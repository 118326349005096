<template>
  <div v-if="do_show" class="generique-fond" @click="hide_all">
    <div class="generique-groupe">
      <transition name="fade" @after-enter="show_name = true">
        <div class="generique-logo" v-show="show_logo">
          <v-img
            :src="require('@/assets/logo/logo200x200.png')"
            width="200px"
            height="200px"
          ></v-img>
        </div>
      </transition>
      <transition name="fade" @after-enter="show_texte = true">
        <div class="generique-name" v-show="show_name">Aneya Agency</div>
      </transition>
      <transition name="fade" @after-enter="show_texte2 = true">
        <div class="generique-texte" v-show="show_texte">
          Affirme ton Univers,
        </div>
      </transition>
      <transition name="fade" @after-enter="hide_all">
        <div class="generique-texte2" v-show="show_texte2">
          Vis de ton projet
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
.generique-fond {
  position: absolute;
  background-color: #0f0f0f;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 10;
  color: #f0f0f0;
  display: flex;
}

.generique-groupe {
  margin: auto auto;
  height: 500px;
}

.generique-logo {
  max-width: 200px;
  margin: 0 auto;
}

.generique-name {
  font-weight: bolder;
  font-size: 4em;
  text-align: center;
}

.generique-texte {
  font-size: 2em;
  text-align: center;
}

.generique-texte2 {
  font-size: 2em;
  text-align: center;
  color: red;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
fade-leave-active {
  transition: opacity 2s;
}
</style>

<script>
export default {
  name: "Generique-accueil",
  data() {
    return {
      should_show: true,
      show_logo: false,
      show_texte: false,
      show_texte2: false,
      show_name: false,
    };
  },
  mounted() {
    this.$el.scrollIntoView();
    this.show_logo = true;
  },
  methods: {
    hide_all() {
      this.$store.commit("user_come");
      setTimeout(() => {
        this.should_show = false;
      }, 1000);
    },
  },
  computed: {
    do_show() {
      return true; /*this.should_show /*&&
        this.$route.name === "home" &&
        this.$store.getters.is_user_new*/
    },
  },
};
</script>
